<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile :loading="loading">
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon>{{ $t("global.create_type") }}</span
          >
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="overflow: auto">
        <v-form ref="form" class="mt-10">
          <v-text-field
            counter="255"
            maxlength="255"
            v-model="item.code"
            required
            :label="$t('global.code')"
            :rules="codeRules"
            outlined
          />
          <v-text-field
            counter="500"
            maxlength="500"
            v-model="item.name"
            required
            :label="$t('global.name')"
            :rules="nameRules"
            outlined
          />
          <ShipStyler v-model="item.style" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed
          color="primary"
          text
          @click="save"
          :disabled="loading"
          :loading="loading"
        >
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShipStyler from "./ShipStyler";

const DEFAULT_ITEM = {
  code: "",
  name: "",
  style: {
    lineColor: "#cccccc",
    fillColor: "#cccccc",
  },
};
export default {
  props: ["open"],
  components: {
    ShipStyler,
  },
  data() {
    return {
      codeRules: [(v) => !!v || this.$t('global.code_rules')],
      nameRules: [(v) => !!v || this.$t('global.name_rules')],
      item: Object.assign({}, DEFAULT_ITEM),
      loading: false,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.item = Object.assign({}, DEFAULT_ITEM);
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("shiptypes/CREATE_SHIP_TYPE", this.item)
          .then(() => {
            this.loading = false;
            this.close();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
