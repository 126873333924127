<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.ship_types") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.ship_type_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>

      <div
        class="float-right"
        v-if="hasUserPermissionToViewEditOrManage('SHIP_TYPES')"
      >
        <v-btn depressed class="mb-5" color="primary" @click="add">
          <v-icon left>mdi-plus-box-multiple</v-icon>
          {{ $t("configurations.new_ship_type") }}
        </v-btn>
      </div>
    </div>
    <List @remove="remove" @edit="edit" :key="componentKey" />
    <CreateShipTypeDialog :open.sync="openAddShipTypeDialog" />
    <EditShipTypeDialog
      :open.sync="openEditShipTypeDialog"
      :item="selectedShipType"
    />
    <DeleteShipTypeDialog
      :open.sync="openDeleteShipTypeDialog"
      :item="selectedShipType"
    />
  </div>
</template>

<script>
import List from "./ShipTypes/List";
import CreateShipTypeDialog from "./ShipTypes/CreateShipTypeDialog";
import EditShipTypeDialog from "./ShipTypes/EditShipTypeDialog";
import DeleteShipTypeDialog from "./ShipTypes/DeleteShipTypeDialog";
export default {
  components: {
    List,
    CreateShipTypeDialog,
    EditShipTypeDialog,
    DeleteShipTypeDialog,
  },
  data() {
    return {
      componentKey: 0,
      openAddShipTypeDialog: false,
      openEditShipTypeDialog: false,
      openDeleteShipTypeDialog: false,
      selectedShipType: null,
    };
  },
  watch: {
    openAddShipTypeDialog(val) {
      if (val == false) this.forceRerender();
    },
    openEditShipTypeDialog(val) {
      if (val == false) this.forceRerender();
    },
    openDeleteShipTypeDialog(val) {
      if (val == false) this.forceRerender();
    },
  },
  methods: {
    add() {
      this.openAddShipTypeDialog = true;
    },
    edit(type) {
      this.selectedShipType = type;
      this.openEditShipTypeDialog = true;
    },
    remove(type) {
      this.selectedShipType = type;
      this.openDeleteShipTypeDialog = true;
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
