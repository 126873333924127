<template>
  <v-input :rules="rules">
    <div style="width: 100%">
      <span class="caption">{{ $t("global.style") }}</span>
      <v-divider />
      <v-row>
        <v-col cols="7">
          <ColorPickerInput
            v-model="lineColor"
            :label="$t('global.exterior_color')"
          />
          <ColorPickerInput v-model="fillColor" :label="$t('global.filling')" />
        </v-col>
        <v-col>
          <ShipPreview :shipStyle="shipStyle" />
        </v-col>
      </v-row>
    </div>
  </v-input>
</template>
<script>
import ColorPickerInput from "./ColorPickerInput";
import ShipPreview from "./ShipPreview";
export default {
  props: ["value", "rules"],
  components: {
    ColorPickerInput,
    ShipPreview,
  },
  computed: {
    shipStyle: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    lineColor: {
      get() {
        return this.shipStyle.lineColor;
      },
      set(val) {
        this.shipStyle.lineColor = val;
        this.$emit("input", this.shipStyle);
      },
    },

    fillColor: {
      get() {
        return this.shipStyle.fillColor;
      },
      set(val) {
        this.shipStyle.fillColor = val;
        this.$emit("input", this.shipStyle);
      },
    },
  },
  methods: {
    setShipStyle() {
      this.shipStyle = {
        lineColor: this.lineColor,
        fillColor: this.fillColor,
      };
    },
  },
};
</script>
