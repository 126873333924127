<template>
  <div style="max-height: calc(100vh - 179px); overflow-y: auto">
    <v-data-table
      :loading="loading"
      :search="search"
      :page.sync="page"
      :headers="headers"
      :items="items"
      :items-per-page="5"
      item-key="id"
      fixed-header
      sort-by="name"
      must-sort
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          single-line
          :label="$t('configurations.search_type')"
          prepend-inner-icon="mdi-magnify"
          outlined
        />
      </template>
      <template
        v-if="hasUserPermissionToViewEditOrManage('SHIP_TYPES')"
        v-slot:item.actions="{ item }"
      >
        <v-menu bottom absolute>
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="hasUserPermissionToManage('SHIP_TYPES')"
              @click="remove(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="hasUserPermissionToViewEditOrManage('SHIP_TYPES')"
              @click="edit(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.style="{ item }">
        <ShipPreview :shipStyle="item.style" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ShipPreview from "./ShipPreview";
export default {
  components: {
    ShipPreview,
  },
  data() {
    return {
      search: "",
      page: 1,
      loading: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("global.code"),
          value: "code",
        },
        {
          text: this.$t("global.name"),
          value: "name",
        },
        {
          text: this.$t("global.style"),
          value: "style",
          sortable: false,
          align: "start",
          width: "10%",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.shiptypes.shipTypesList;
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("shiptypes/GET_SHIP_TYPES").then(() => {
      this.loading = false;
    });
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("remove", item);
    },
  },
};
</script>
