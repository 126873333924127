<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("configurations.edit_type") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="overflow: auto">
        <v-form ref="form" class="mt-10" v-if="!!itemToEdit">
          <v-text-field
            counter="255"
            maxlength="255"
            v-model="itemToEdit.code"
            required
            label="Código"
            :rules="codeRules"
            outlined
          />
          <v-text-field
            v-model="itemToEdit.name"
            counter="500"
            maxlength="500"
            required
            label="Nome"
            :rules="nameRules"
            outlined
          />
          <ShipStyler v-model="item.style" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed
          color="primary"
          text
          @click="save"
          :disabled="loading"
          :loading="loading"
        >
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShipStyler from "./ShipStyler";

export default {
  props: ["open", "item"],
  components: {
    ShipStyler,
  },
  data() {
    return {
      codeRules: [(v) => !!v || this.$t('global.code_rules')],
      nameRules: [(v) => !!v || this.$t('global.name_rules')],
      loading: false,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    itemToEdit: {
      get() {
        return this.item;
      },

      set(val) {
        this.$emit("update:item", val);
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("shiptypes/UPDATE_SHIP_TYPE", this.itemToEdit)
          .then(() => {
            this.loading = false;
            this.close();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
