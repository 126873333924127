<template>
  <div class="preview">
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200.000000 100.000000"
      preserveAspectRatio="xMidYMid meet"
      class="svg-preview"
    >
      <g>
        <path
          d="m7.22221,49.58332l0.69445,-4.44444l2.36111,-5.13889l1.66667,-4.58333l2.91667,-4.72222l3.47222,-4.72222l1.52778,-1.25l79.72222,-1.80556l84.58333,3.05556l1.80556,1.66667l0.97222,6.11111l0.13889,7.22222l0.27778,8.05556l0.13889,8.19444l-0.27778,5.83333l-1.11111,5.83333l-2.77778,3.19444l-81.38888,2.91667l-81.38888,-1.25l-7.22222,-8.75l-3.05556,-5l-2.22222,-5.83333l-0.83334,-4.58334z"
          opacity="NaN"
          :stroke="lineColor"
          :fill="fillColor"
        />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: ["shipStyle"],
  computed: {
    lineColor() {
      return this.shipStyle ? this.shipStyle.lineColor : "#fff";
    },
    fillColor() {
      return this.shipStyle ? this.shipStyle.fillColor : "#fff";
    },
  },
};
</script>
<style scoped>
.preview {
  width: 100px;
  position: relative;
  padding: 0px;
  margin: 0px;
}

.centered {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-100%, -50%);
}
</style>
