<template>
  <v-text-field v-model="value" readonly :label="label">
    <template v-slot:append>
      <v-menu
        v-model="colorPickerMenu"
        top
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <div class="ma-1" :style="swatchStyleColorPicker" v-on="on" />
        </template>
        <v-card tile>
          <v-card-text class="pa-0">
            <v-color-picker v-model="valueColorPicker" flat mode="hexa" />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
const DEFAULT_COLOR = "#CCCCCC";
export default {
  props: ["value", "label"],
  data() {
    return {
      colorPickerMenu: false,
    };
  },
  computed: {
    swatchStyleColorPicker() {
      return {
        border: "1px solid #ccc",
        backgroundColor: this.value,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: this.colorPickerMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    valueColorPicker: {
      get() {
        return this.value ?? DEFAULT_COLOR;
      },

      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>