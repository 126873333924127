<template>
  <ShipTypes />
</template>

<script>
import ShipTypes from "@/components/Configurations/ShipTypes";

export default {
  components: {
    ShipTypes,
  },
};
</script>
